<template>
  <div v-if="!this.$func.checkPermission('ตั้งค่าเว็บไซต์')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <card v-loading="loading">
          <div slot="header">
            <h4 class="m-0">ตั้งค่าเว็บไซต์</h4>
          </div>
          <div>
            <form>
              <div class="row">
                <div class="col-12 col-lg-5">
                  <fg-input
                    :disabled="disabled"
                    v-model="logo"
                    type="text"
                    placeholder="กรอก URL โลโก้"
                  >
                    <span slot="label"> URL โลโก้ </span>
                  </fg-input>
                  <fg-input
                    :disabled="disabled"
                    v-model="title"
                    type="text"
                    placeholder="กรอก title เว็บไซต์"
                  >
                    <span slot="label"> Title เว็บไซต์ </span>
                  </fg-input>
                  <fg-input
                    :disabled="disabled"
                    v-model="name"
                    type="text"
                    placeholder="กรอกชื่อเว็บไซต์"
                  >
                    <span slot="label"> ชื่อเว็บไซต์ </span>
                  </fg-input>
                  <fg-input
                    :disabled="disabled"
                    v-model="line_id"
                    type="text"
                    placeholder="กรอกลิ้งค์ไลน์"
                  >
                    <span slot="label"> ลิ้งค์ไลน์ </span>
                  </fg-input>
                  <fg-input
                    :disabled="disabled"
                    v-model="line_id2"
                    type="text"
                    placeholder="กรอกลิ้งค์ไลน์สำรอง"
                  >
                    <span slot="label"> ลิ้งค์ไลน์สำรอง </span>
                  </fg-input>
                  <hr />
                  <div class="mb-2">
                    <div class="mb-2">ตั้งค่าการถอน</div>
                    <div class="form-check form-check-inline">
                      <label
                        for="withdraw_custom1"
                        class="d-flex align-items-center mr-3"
                      >
                        <input
                          v-model="withdraw_custom"
                          class="form-check-input"
                          type="radio"
                          name="withdraw_custom"
                          id="withdraw_custom1"
                          value="0"
                          :checked="withdraw_custom === 0"
                          @change="withdraw_custom = 0"
                          :disabled="disabled"
                        />
                        <h5 class="ml-2 my-0">ถอนทั้งหมด</h5>
                      </label>
                      <label
                        for="withdraw_custom2"
                        class="d-flex align-items-center"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="withdraw_custom"
                          id="withdraw_custom2"
                          value="1"
                          :checked="withdraw_custom === 1"
                          @change="withdraw_custom = 1"
                          :disabled="disabled"
                        />
                        <h5 class="ml-2 my-0">ถอนกรอกจำนวน</h5>
                      </label>
                    </div>
                  </div>

                  <fg-input
                    v-model="withdraw_min"
                    :disabled="disabled"
                    type="text"
                    placeholder=""
                  >
                    <span slot="label"> ถอนขั้นต่ำ </span>
                  </fg-input>

                  <div class="mb-2">
                    <div class="mb-2">สถานะการถอน</div>
                    <div class="form-check form-check-inline">
                      <label
                        for="withdraw_active1"
                        class="d-flex align-items-center mr-3"
                      >
                        <input
                          v-model="withdraw_active"
                          class="form-check-input"
                          type="radio"
                          name="withdraw_active"
                          id="withdraw_active1"
                          value="0"
                          :checked="withdraw_active === 0"
                          @change="withdraw_active = 0"
                          :disabled="disabled"
                        />
                        <h5 class="ml-2 my-0">ปิดถอน</h5>
                      </label>
                      <label
                        for="withdraw_active2"
                        class="d-flex align-items-center"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="withdraw_active"
                          id="withdraw_active2"
                          value="1"
                          :checked="withdraw_active === 1"
                          @change="withdraw_active = 1"
                          :disabled="disabled"
                        />
                        <h5 class="ml-2 my-0">เปิดถอน</h5>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div slot="footer">
            <template v-if="!disabled">
              <el-button type="success" size="default" @click="updateWebsite">
                <i class="fa fa-save"></i>
                บันทึก
              </el-button>
              <el-button
                type="danger"
                size="default"
                @click="disabled = !disabled"
              >
                <i class="fa fa-times" aria-hidden="true"></i> ยกเลิก
              </el-button>
            </template>
            <el-button
              v-else
              type="warning"
              size="default"
              @click="disabled = !disabled"
            >
              <i class="fa fa-edit"></i>
              แก้ไข
            </el-button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, RadioGroup, RadioButton, Radio } from "element-ui";
import { FormGroupInput as FgInput } from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    [Radio.name]: Radio,
    FgInput,
  },
  data() {
    return {
      loading: true,
      logo: "",
      title: "",
      keyword: "",
      name: "",
      line_id: "",
      line_id2: "",
      disabled: true,
      withdraw_custom: "",
      withdraw_min: 0,
      withdraw_active: 0,
    };
  },
  mounted() {
    this.getWebsite();
  },
  methods: {
    async getWebsite() {
      this.loading = true;
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${this.$hostname}/website`,
          headers: {
            Authorization: this.$cookies.get("token"),
          },
        };
        const res = await axios.request(config);
        this.loading = false;
        const data = res.data;
        if (data.res_code === "00") {
          const result = data.res_result[0];
          this.logo = result.logo;
          this.title = result.title;
          this.keyword = result.keyword;
          this.name = result.name;
          this.line_id = result.line_id;
          this.line_id2 = result.line_id2;
          this.withdraw_custom = result.withdraw_custom;
          this.withdraw_min = result.withdraw_min;
          this.withdraw_active = result.withdraw_active;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async updateWebsite() {
      try {
        const data = JSON.stringify({
          logo: this.logo,
          title: this.title,
          name_website: this.name,
          line_id: this.line_id,
          line_id2: this.line_id2,
          withdraw_custom: this.withdraw_custom,
          withdraw_min: this.withdraw_min,
          withdraw_active: this.withdraw_active,
        });
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${this.$hostname}/update_website`,
          headers: {
            Authorization: this.$cookies.get("token"),
            "Content-Type": "application/json",
          },
          data: data,
        };

        Swal.fire({
          title: "กำลังบันทึก...",
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        Swal.showLoading();

        const res = await axios.request(config);
        this.disabled = true;
        const responseData = res.data;
        if (responseData.res_code === "00") {
          this.getWebsite();
          Swal.fire({
            title: "แจ้งเตือน",
            text: responseData.res_text,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        } else {
          Swal.fire("แจ้งเตือน", responseData.res_text, "error");
        }
      } catch (error) {
        // Handle error
      }
    },
  },
};
</script>
